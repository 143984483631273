<template>
  <div class='list'>
    <div class="globle_border" v-loading="loading">
      <div class="search">
        <el-input v-model="search" placeholder="搜索用户编号" suffix-icon="el-icon-search" @change="onSearch" clearable></el-input>
      </div>
      <div class="globle_table">
        <el-table :data="tableData" style="width: 100%" max-height="540">
          <el-table-column prop="number" label="序号" width='100'>
            <template slot-scope="scope">
              {{ scope.$index + 1 + (currentPage - 1) * pageSize }}
            </template>
          </el-table-column>
          <el-table-column prop="userCode" label="用户编号" show-overflow-tooltip></el-table-column>
          <el-table-column prop="userName" label="用户昵称" show-overflow-tooltip></el-table-column>
          <el-table-column prop="userPhone" label="电话号码"></el-table-column>
          <el-table-column prop="createdTime" label="创建时间" show-overflow-tooltip></el-table-column>
          <el-table-column label="操作" header-align="center" align="center" fixed="right">
            <template slot-scope="scope">
              <el-button class="btn" type="primary" size="small" plain @click="handleRecord(scope.row)">消费记录</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
    <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page.sync="currentPage" :page-size="pageSize" :page-sizes="pageSizeArr" layout="prev, pager, next, jumper, sizes, total" :total="totalItemsCount">
    </el-pagination>
  </div>
</template>

<script>

export default {
  data () {
    return {
      loading: false,
      search: '',
      tableData: [],
      currentPage: 1, // 当前页码
      pageSize: 10, // 每页显示的行数
      pageSizeArr: [10, 20, 50], // 每页显示的行数(可选)
      totalItemsCount: 0, // 总记录数（需要从后端获取）
    }
  },
  created () {
    this.getSupplierInfoPageList()
  },
  methods: {
    // 列表
    getSupplierInfoPageList () {
      this.loading = true;
      this.$axios.get(this.$api.shopMallUserList, {
        params: {
          userCode: this.search,
          page: this.currentPage,
          pageSize: this.pageSize,
          //  status: 1, // 审核状态 ( 0:待审核 1:审核通过 2:审核不通过 )
        },
      }).then((res) => {
        this.tableData = res.data.result.list;
        this.totalItemsCount = res.data.result.totalCount;
        this.loading = false;
      }).catch(() => {
        this.loading = false;
      });
    },
    // 搜索
    onSearch () {
      this.tableData = []
      this.currentPage = 1
      this.getSupplierInfoPageList()
    },

    // 切换每页显示的条数
    handleSizeChange (e) {
      this.tableData = []
      this.pageSize = e;
      this.getSupplierInfoPageList();
    },
    // 换页
    handleCurrentChange (e) {
      this.tableData = []
      this.currentPage = e;
      this.getSupplierInfoPageList();
    },
    // 消费记录
    handleRecord (row) {
      this.$store.commit("setSideMenuActive", '/order/onlineOrder');
      this.$router.push({ path: '/order/onlineOrder', query: { userCode: row.userCode }, });

      // console.log(row);

    },



  },
}
</script>

<style scoped lang='scss'>
.list {
  .search {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 44px;
    margin-bottom: 20px;

    .el-input {
      margin-left: 20px;
      width: 300px;
    }

    .el-button {
      margin-left: 20px;
    }
  }

  .show_img {
    color: #069bbc;
    cursor: pointer;

    &:hover {
      opacity: 0.7;
    }
  }
}
</style>